<template>
<a-layout>
    <a-layout-content >
        <a-row class="friday">
            <div v-if="!_mobile" style="padding-top:80px">
                <a-col :span="24">
                    <div class="s-friday-bar1">
                        <div class="s-friday-font1">스토어링크 <br/>키워드상세분석</div>
                        <div class="s-friday-font2">
                            국내 네이버 검색 트렌드 및 쇼핑 분야별 키워드 현황을 확인 해 보세요
                        </div>
                        <div style="margin-top:30px">
                            <a-input v-model="keyword" @focus="togglePicker()"  @blur="toggleBlur()" placeholder="온라인쇼핑몰 키워드를 입력해주세요" style="width:50%" @pressEnter="onSearch()">
                                <i style="font-size:20px" v-bind:class="[ focus  ? 'active' : '' ]" slot="suffix" class="far fa-search"></i>
                            </a-input>
                        </div>
                    </div>

                </a-col>

                <a-col :span="span" :offset="offSet">
                    <a-row>
                        <a-col :span="1" @click="onPrevious()" style="cursor:pointer">
                            <img style="width:100%;padding-top:450px;padding-right:30px" src="@/assets/images/left.png" />
                        </a-col>
                        <a-col :span="22">

                            <div class="s-friday-font3" style="padding-left:2px">분야별 인기검색어</div>
                            <div style="margin-top:30px;margin-bottom:20px;padding-left:4px">

                                <a-select default-value="패션의류" size="large" style="width: 200px" @change="onSelectKeywordType">
                                    <a-select-option value="50000000">
                                        패션의류
                                    </a-select-option>
                                    <a-select-option value="50000001">
                                        패션잡화
                                    </a-select-option>
                                    <a-select-option value="50000002">
                                        화장품/미용
                                    </a-select-option>
                                    <a-select-option value="50000003">
                                        디지털/가전
                                    </a-select-option>
                                    <a-select-option value="50000004">
                                        가구/인테리어
                                    </a-select-option>
                                    <a-select-option value="50000005">
                                        출산/육아
                                    </a-select-option>
                                    <a-select-option value="50000006">
                                        식품
                                    </a-select-option>
                                    <a-select-option value="50000007">
                                        스포츠/레저
                                    </a-select-option>
                                    <a-select-option value="50000008">
                                        생활/건강
                                    </a-select-option>
                                    <a-select-option value="50000009">
                                        여가/생활편의
                                    </a-select-option>
                                    <a-select-option value="50000010">
                                        면세점
                                    </a-select-option>

                                </a-select>
                            </div>
                            <a-row>
                                <a-col :span="6" v-for="item in getListBySelectedCategory" v-if="item.show" :key="item.date">
                                    <div class="s-friday-bar2">
                                        <div class="s-friday-font4">{{item.datetime}}</div>
                                        <div class="s-friday-bar3"></div>

                                        <div style="padding:20px">
                                            <div v-for="sitem in item.ranks" :key="sitem.rank" style="padding:9px">
                                                <span class="s-friday-bar4">{{sitem.rank}}.&nbsp;&nbsp;&nbsp;&nbsp;</span>{{sitem.keyword}}
                                            </div>
                                        </div>
                                    </div>

                                </a-col>

                            </a-row>

                        </a-col>
                        <a-col :span="1" @click="onNext()" style="cursor:pointer">
                            <img style="width:100%;padding-top:450px;padding-left:30px" src="@/assets/images/right.png" />
                        </a-col>
                    </a-row>
                </a-col>
            </div>
            <div v-else>
                <a-col :span="24">

                    <div class="s-friday-bar1" style="padding-top:0px;height:350px">
                        <div class="s-friday-font1">스토어링크 키워드분석</div>
                        <div class="s-friday-font2">
                            국내 네이버 검색 트렌드 및 쇼핑 분야별 <br />키워드 현황을 확인 해 보세요
                        </div>
                        <div style="margin-top:30px">
                            <a-input v-model="keyword" @focus="togglePicker()"  @blur="toggleBlur()"  placeholder="온라인쇼핑몰 키워드를 입력해주세요" style="width:90%" @pressEnter="onSearch()">
                             
                                <i style="font-size:20px" v-bind:class="[ focus  ? 'active' : '' ]" slot="suffix" class="far fa-search"></i>


                            </a-input>
                        </div>
                    </div>

                </a-col>

                <a-col :span="24" style="padding:10px">

                    <div class="s-friday-font3" style="padding-left:2px">분야별 인기검색어</div>
                    <div style="margin-top:30px;margin-bottom:20px;padding-left:4px">

                        <a-select default-value="패션의류" size="large" style="width: 200px" @change="onSelectKeywordType">
                            <a-select-option value="50000000">
                                패션의류
                            </a-select-option>
                            <a-select-option value="50000001">
                                패션잡화
                            </a-select-option>
                            <a-select-option value="50000002">
                                화장품/미용
                            </a-select-option>
                            <a-select-option value="50000003">
                                디지털/가전
                            </a-select-option>
                            <a-select-option value="50000004">
                                가구/인테리어
                            </a-select-option>
                            <a-select-option value="50000005">
                                출산/육아
                            </a-select-option>
                            <a-select-option value="50000006">
                                식품
                            </a-select-option>
                            <a-select-option value="50000007">
                                스포츠/레저
                            </a-select-option>
                            <a-select-option value="50000008">
                                생활/건강
                            </a-select-option>
                            <a-select-option value="50000009">
                                여가/생활편의
                            </a-select-option>
                            <a-select-option value="50000010">
                                면세점
                            </a-select-option>

                        </a-select>
                    </div>
                    <div style="overflow-x:scroll;overflow-y: hidden;display:flex; " id="mobileDiv">
                        <div v-for="item in getListBySelectedCategory" :key="item.date">
                            <div class="s-friday-bar2" style="width:300px">
                                <div class="s-friday-font4">{{item.datetime}}</div>
                                <div class="s-friday-bar3"></div>

                                <div style="padding:20px">
                                    <div v-for="sitem in item.ranks" :key="sitem.rank" style="padding:9px">
                                        <span class="s-friday-bar4">{{sitem.rank}}.&nbsp;&nbsp;&nbsp;&nbsp;</span>{{sitem.keyword}}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </a-col>

            </div>
        </a-row>
    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
import {
  getLicense,
  replaceAll,
  getCurrentTime,
  getCurrentTime2,
  getCurrentTimeNew,
  isNaN,
  formatNumber,
  getSR,
  isMobile,
  showError,
  showSuccess
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
export default {
  components: {
    NavFooter
  },
  data() {
    return {
      focus: false,
      startPos: 8,
      endPos: 11,
      keyword: "",
      offSet: 4,
      span: 16,
      limit: 7,
      keywordType: "50000000",
      singleSelctedData: [],
      categoryList: [
        {
          code: 50000000,
          data: []
        },
        {
          code: 50000001,
          data: []
        },
        {
          code: 50000002,
          data: []
        },
        {
          code: 50000003,
          data: []
        },
        {
          code: 50000004,
          data: []
        },
        {
          code: 50000005,
          data: []
        },
        {
          code: 50000006,
          data: []
        },
        {
          code: 50000007,
          data: []
        },
        {
          code: 50000008,
          data: []
        },
        {
          code: 50000009,
          data: []
        },
        {
          code: 50000010,
          data: []
        }
      ]
    };
  },
  mounted() {
    this.onLoadData();
    document.getElementById("fullroot").scrollTop = 0;
  },
  computed: {
    getListBySelectedCategory() {
      return this.categoryList.find(
        item => item.code == parseInt(this.keywordType)
      ).data;
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 20;
      this.offSet = _mobile ? 0 : 2;
      this.limit = _mobile ? -1 : 7;
      return _mobile;
    }
  },
  methods: {
    onSearch() {
      let routeData = this.$router.resolve({
        name: "fridaysearch",
        query: {
          cid: this.keyword
        }
      });
      window.open(routeData.href, "_self");
    },
    togglePicker() {
      this.focus = true;
    },
    toggleBlur() {
      this.focus = false;
    },
    onMove() {
      var self = this;
      this.categoryList = this.categoryList.filter(item => {
        if (item.code == parseInt(self.keywordType)) {
          var _dummy = item.data;

          for (var i = 0; i < 12; i++) {
            if (i >= self.startPos && i <= self.endPos) {
              _dummy[i].show = true;
            } else {
              _dummy[i].show = false;
            }
          }
          item.data = _dummy;
          console.log(item);
        }
        return item;
      });
    },
    onPrevious() {
      if (this.startPos > 0) {
        this.startPos--;
        this.endPos--;
      }
      this.onMove();
    },
    onNext() {
      if (this.endPos < 11) {
        this.startPos++;
        this.endPos++;
      }
      this.onMove();
    },
    onWait() {
      showError({
        notify: this.$vs.notify,
        msg: "아직 준비중입니다."
      });
    },
    onSelectKeywordType(value) {
      this.keywordType = value;
      if (this._mobile) {
        document.getElementById("mobileDiv").scrollLeft = 4000;
      }
    },
    async getSingleData(code, index) {
      var self = this;
      var db = firebase.firestore();
      await db
        .collection("naver_datalab")
        .where("code", "==", code)
        .orderBy("eventtimestamp", "desc")
        .limit(1)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            var _dummy = [];
            var _idx = 0;

            doc.data().data.forEach(ii => {
              var _t = ii;
              if (_idx > self.limit) {
                _t["show"] = true;
              } else {
                _t["show"] = false;
              }

              _t["idx"] = _idx;
              _idx++;
              _dummy.push(_t);
            });
            self.categoryList[index].data = _dummy;
          });
        });
    },
    async onLoadData() {
      for (var i = 0; i < 11; i++) {
        await this.getSingleData(this.categoryList[i].code, i);
        if (i == 0 && this._mobile) {
          document.getElementById("mobileDiv").scrollLeft = 4000;
        }
      }
    }
  }
};
</script>

<style>
.s-friday-bar1 {
  width: 100%;
  height: 292px;
  background-color: #0264fb;
  text-align: center;
}

.s-friday-bar2 {
  height: 579px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  background-color: #ffffff;
}

.s-friday-bar3 {
  width: 100px;
  height: 1px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
  margin: 0 auto;
  margin-bottom: 30px;
}

.s-friday-bar4 {
  font-family: "Muli";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
}

.s-friday-font1 {
  padding-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.88px;
  text-align: center;
  color: #ffffff;
}

.s-friday-font2 {
  padding-top: 10px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.13px;
  text-align: center;
  color: #ffffff;
}

.s-friday-font3 {
  padding-top: 60px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.74px;
  color: #000000;
}

.s-friday-font4 {
  padding-top: 31px;
  padding-bottom: 31px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #000000;
  text-align: center;
}

.friday input {
  height: 60px;
  background-color: #67a2fc;
  color: white;
  border-radius: 5px !important;
  border: 1px solid #0264fb;
}

.friday input:focus {
  color: #7d7d7d;
  background-color: white;
}

.friday .ant-input-affix-wrapper i {
  color: white;
}

.friday .ant-input-affix-wrapper i.active {
  color: #7d7d7d;
}

.friday input:focus::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7d7d7d;
  opacity: 1; /* Firefox */
}

.friday input:focus:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7d7d7d;
}

.friday input:focus::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #7d7d7d;
}

.friday input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.friday input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: white;
}

.friday input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: white;
}
</style>
